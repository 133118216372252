import React from 'react';
import {useRef, useEffect} from 'react'

export default function StarWarsIntro() {
    const audioRef = useRef(null);
    const animationRef = useRef(null);
    const containerRef = useRef(null);

    useEffect(() => {

    // Audio to play the opening crawl
    //let audio = audioRef.current;
    //audio.play();
      
    })

  return (
<article ref={containerRef} class="starwars">
  <audio ref={audioRef} preload="auto">
    <source src="https://s.cdpn.io/1202/Star_Wars_original_opening_crawl_1977.ogg" type="audio/ogg" />
    <source src="https://s.cdpn.io/1202/Star_Wars_original_opening_crawl_1977.mp3" type="audio/mpeg" />
  </audio>
   
  <div ref={animationRef} class="animation">
    
  <section class="sw-intro">
    A long long time ago, when files <br/> were just a dream....
  </section>
    
  <section class="titles">
    <div contenteditable="true" spellcheck="false">  
      <p>
      1024 BC was the first time known to human where phoenician sailors have been found to transfer the first files.
      </p>
      <p>
      Since then File transfer has seen many massive improvements: The invention of wooden file barrels has brought
          safety and reliability to the old unreliable clay pots.
      </p>
      <p>
      The Jet era has brought speed and massive global distribution and the digital age has brought added layers of security.
      We at FileDriver.com are dwarfs standing proud on the shoulders of giants, bringing close to realtime file delivery.
      </p>

      <p>
      STABLE, SECURE, FAST, GLOBAL!!
      </p>
      <p>
      We are here to innovate, together with you we will discover the future of file sharing.
      </p>
      </div>
  </section>
  
<section class="logo">
<h1>FileDriver.com</h1>
  </section>
  </div>
</article>
  );
}
