import React from 'react';

import { useStateValue } from '../state';
import Fade from './fade';
import StarWarsIntro from './starWarsIntro'
import {useEffect} from 'react'
import Link from './link';

export default function Intro() {
  const [{ start }, dispatch] = useStateValue();

  useEffect(() => { 
    let timeout = setTimeout(() => dispatch({ type: 'START' }),25000);
    return () => {
        clearTimeout(timeout);
    }
    });
  
  return (
    <Fade className="intro" show={!start}>
      <StarWarsIntro/>
      <div>
        <Link className="skip-intro" onClick={() => dispatch({ type: 'START' })}>
            Skip
        </Link>
          </div>
    </Fade>
  );
}
