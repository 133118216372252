import moment from 'moment';
import React from 'react';

import { useStateValue } from '../state';
import Description from './description';
import Fade from './fade';
import AccumulativeTransfersChart from './accumulativeTransfersChart';
import Link from './link';


export default function Overlay() {
  const [
    { focusedMarker, lastUpdated, start, totalUploadedFiles, transferTypesByDate }
  ] = useStateValue();
  //const [showAbout, setShowAbout] = useState(false);

  //const showOverlay = start && !showAbout && !focusedMarker;

  const showOverlay = start && !focusedMarker;

  /*const renderTopSharingCities = () => {
    return (
      <>
          TOP 5 SHARING CITIES
          {markers.slice(0, 5).map((marker) => (
            <Link key={marker.city}>
              <h2 onClick={() => dispatch({ type: 'FOCUS', payload: marker })}>
                {marker.city} ({marker.value})
              </h2>
            </Link>
          ))}
      </>
    );
  }*/
  return (
    <>
      <Fade className="overlay" show={showOverlay}>
        <div className="header">
          <div>
            <h2>FileDriver.com</h2>
            <div className="overlay-subtitle">
              <Description />
            </div>
          </div>
          <div className="nudge-right">

          </div>
        </div>
        <div   className="content">
        </div>
        <div className="content-left">
        WORK FILE TRANSFERS
        <h1 style={{fontSize: '100px'}}>{totalUploadedFiles}</h1>
        </div>
        <div className="content-left-bottom">
        <Link link={'TRANSFERS_CHART'}>
              <AccumulativeTransfersChart showPointerOnHover={true} data = {transferTypesByDate} width={450} height={225}/>
            </Link>
        </div>
        <div className="footer">
          Updated on {moment(lastUpdated).format('MMM D, YYYY')}
        </div>
      </Fade>
    </>
  );
}
