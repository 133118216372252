import React, { Component } from 'react';
import { ComposedChart, Line, Bar, XAxis,
  YAxis, Tooltip, Legend, CartesianGrid } from 'recharts';

// eslint-disable-next-line react/prefer-stateless-function
export default class AccumulativeTransfersChart extends Component {
  render () {
    const RENDER_LINES = false;

    const CustomTooltip = (props) => {
      //const { active, payload, external, label } = props;
      const { active, external, label } = props;
      if (active) {
        const style = {
          padding: 6,
          backgroundColor: '#ffff',
          opacity: 0.9,
          border: '1px solid #ccc',
          borderRadius: '16px'
        };
        if(this.props.showTooltip) {
          const currData = external.filter(entry => (entry.timeStamp === label))[0];
          let timeStampArr = currData.timeStamp.split('/');
          let date = `${timeStampArr[1]}/${timeStampArr[0]}/${timeStampArr[2]}`
          const totalTransfers = currData.totalTransfers || 0;
          const workTransfers = currData.workTransfers || 0;
          const personalTransfers = currData.personalTransfers || 0;
          const unknownTransfers = currData.unknownTransfers || 0;
          return (
            currData &&
            <div className="area-chart-tooltip" style={style}>
              <p style={{color: "black"}}>{date}</p> 
              <p style={{color: "#00F"}}>Total transfers: {totalTransfers}</p> 
              <p style={{color: "#413ea0"}}>Personal transfers: {personalTransfers} <br/> ({Math.round(personalTransfers / totalTransfers *100)}%)</p> 
              <p style={{color: "#FF7F50"}}>unknown transfers: {unknownTransfers} <br/> ({Math.round(unknownTransfers / totalTransfers *100)}%)</p> 
              <p style={{color: "#24B47E"}}>Work transfers: {workTransfers} <br/> ({Math.round(workTransfers / totalTransfers*100)}%)</p> 
            </div>
          );
        }
        return (
          <div className="area-chart-tooltip" style={{backgroundColor: '#413ea0', padding:'0'}}>
          <p style={{fontSize: '12px'}}>Click to enlarge and see tooltip data</p>
        </div>
        );
        }

    
      return null;
    };

    return (
      <div className="line-charts">
        <div lassName="composed-chart-wrapper">
          <ComposedChart cursor={this.props.showPointerOnHover ? 'pointer' : 'default'} onClick={this.props.onClick} width={this.props.width} height={this.props.height} data={this.props.data}
            margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
            <XAxis dataKey="timeStamp"/>
            <YAxis />
            <Legend xAxisId="asd"/>
            <CartesianGrid stroke="#f5f5f5" />
            <Tooltip content={<CustomTooltip external={this.props.data} />}/>
            <Bar name="Work transfers" dataKey="workTransfers" stackId="a" fill="#24B47E"/>
            <Bar name="Personal transfers" dataKey="personalTransfers"  stackId="a" fill="#413ea0"/>
            <Bar name="Unknown transfers" dataKey="unknownTransfers"  stackId="a" fill="#FF7F50"/>
            {RENDER_LINES && <Line type="monotone" dataKey={i => i.workTransfers/i.totalTransfers*100} stroke="#FFDD03" />}
            {RENDER_LINES && <Line type="monotone" dataKey="personalTransfers" stroke="#ff7200" />}
            {RENDER_LINES && <Line type="monotone" dataKey="unknownTransfers" stroke="#FFDD03" />}
          </ComposedChart>
        </div>
      </div>
    );
  }
}