import React from 'react';

import { useStateValue } from '../state';
import Button from './button';
import Fade from './fade';

export function getRandomMarker({ focusedMarker, markers }) {
  const filteredMarkers = markers.filter((marker) => {
    return marker.id !== focusedMarker?.id;
  });
  return filteredMarkers[Math.floor(Math.random() * filteredMarkers.length)];
}

export default function Details() {
  const [
    { focusedMarker, markers, fileTypesByCity },
    dispatch,
  ] = useStateValue();
  const randomMarker = getRandomMarker({ focusedMarker, markers });

  let content;
  if (focusedMarker) {
    const { city, countryCode, countryName, value } = focusedMarker;
    //const url = getSearchUrl(city, countryName, config.keyword);
    const files = fileTypesByCity[countryCode][city] || [];

    content = (
      <>
        <div className="header">
          <Button
            label="Back to globe"
            onClick={() => dispatch({ type: 'FOCUS' })}
          />
          <Button
            label="Random City"
            onClick={() => dispatch({ type: 'FOCUS', payload: randomMarker })}
          />
        </div>
        <div className="content">
          <h2>
            {city}, {countryName} ({value})
          </h2>
          <div className="details-content">
            POPULAR FILE TYPES
            {files.map(({ type, value }) => {
              return (
                <div
                  key={`${type}-${value}`}>
                  {type} ({Math.round(parseInt(value) / fileTypesByCity[countryCode][city].map(i => parseInt(i.value)).reduce((sum, x) => sum + x) * 100)} %)
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  }

  return (
    <Fade className="details" show={focusedMarker}>
      {content}
    </Fade>
  );
}
