import React from 'react';

//import { useStateValue } from '../state';
//import Link from './link';

export default function Description() {
  //const [{ config }] = useStateValue();

  return (
    <>
      The best file delivery system in the galaxy 
    </>
    /*<>
      /*Visualizing <b>{`"${config.keyword}"`}</b> Google Trends with{' '}
      <Link link="REACT_GLOBE_GITHUB">react-globe</Link>
    </>*/
  );
}
