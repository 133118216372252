import React from 'react';
import Fade from './fade';
import Link from './link';

export default function Charts({ onHide, headerText, subHeaderText, chartRenderer, nextLink, backLink }) {


  return (
    <Fade className="charts" show={true}>
              <div className="header">
              <Link className="button" link={backLink}>
                Back
            </Link>
            {nextLink && <Link className="button" link={nextLink}>
                Signups
            </Link>}
        </div>
      <div className="charts-view-content">
        <h1>{headerText}</h1>
        <h2>{subHeaderText}</h2>
        {chartRenderer && chartRenderer()}
      </div>
    </Fade>
  );
}
