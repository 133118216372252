import React, { Component } from 'react';
import { ComposedChart, Line, Bar, XAxis,
  YAxis, Tooltip, Legend, CartesianGrid } from 'recharts';

// eslint-disable-next-line react/prefer-stateless-function
export default class AccumulativeSignupsChart extends Component {
  render () {
    const RENDER_LINES = false;

    const CustomTooltip = (props) => {
      //const { active, payload, external, label } = props;
      const { active, external, label } = props;
      if (active) {
        const style = {
          padding: 6,
          backgroundColor: '#ffff',
          opacity: 0.9,
          border: '1px solid #ccc',
          borderRadius: '16px'
        };
        if(this.props.showTooltip) {
          const currData = external.filter(entry => (entry.timeStamp === label))[0];
          let timeStampArr = currData.timeStamp.split('/');
          let date = `${timeStampArr[1]}/${timeStampArr[0]}/${timeStampArr[2]}`
          const totalSignups = currData.totalSignups || 0;
          const workSignups = currData.workSignups || 0;
          const personalSignups = currData.personalSignups || 0;
          const totalActivations = currData.totalActivations || 0;
          return (
            currData &&
            <div className="area-chart-tooltip" style={style}>
              <p style={{color: "black"}}>{date}</p> 
              <p style={{color: "#00F"}}>Total signups: {totalSignups}</p> 
              <p style={{color: "#413ea0"}}>Personal signups: {personalSignups} <br/> ({Math.round(personalSignups / totalSignups *100)}%)</p> 
              <p style={{color: "#24B47E"}}>Work signups: {workSignups} <br/> ({Math.round(workSignups / totalSignups*100)}%)</p> 
              <p style={{color: "#ff7300"}}>Activated signups: {totalActivations} <br/> ({Math.round(totalActivations / totalSignups*100)}%)</p> 
            </div>
          );
        }
        return (
          <div className="area-chart-tooltip" style={{backgroundColor: '#413ea0', padding:'0'}}>
          <p style={{fontSize: '12px'}}>Click to enlarge and see tooltip data</p>
        </div>
        );
        }

    
      return null;
    };

    return (
      <div className="line-charts">
        <div lassName="composed-chart-wrapper">
          <ComposedChart cursor={this.props.showPointerOnHover ? 'pointer' : 'default'} onClick={this.props.onClick} width={this.props.width} height={this.props.height} data={this.props.data}
            margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
            <XAxis dataKey="timeStamp"/>
            <YAxis />
            <Legend xAxisId="asd"/>
            <CartesianGrid stroke="#f5f5f5" />
            <Tooltip content={<CustomTooltip external={this.props.data} />}/>
            <Bar name="Work signups" dataKey="workSignups" stackId="a" fill="#24B47E"/>
            <Bar name="Personal signups" dataKey="personalSignups"  stackId="a" fill="#413ea0"/>
            <Line strokeWidth={2} type="monotone" dataKey="totalActivations" stroke="#ff7300" legendType='none' hide={false} />
            {RENDER_LINES && <Line type="monotone" dataKey={i => i.workSignups/i.totalSignups * 100} stroke="#FFDD03" />}
            {RENDER_LINES && <Line type="monotone" dataKey="personalSignups" stroke="#ff7200" />}
          </ComposedChart>
        </div>
      </div>
    );
  }
}