import React, { createContext, useContext, useReducer } from 'react';

import config from './config';
//import data from './data/data.json';
//import data from './data/filedriver_data.json';
import data from './data/data_dev.json';


//const { lastUpdated, relatedTopics, trends } = data;
const { lastUpdated, fileTypesByCity, transfers, totalUploadedFiles, transferPaths, transferTypesByDate, signupTypesPerDate} = data;

let search = window.location.search;
let params = new URLSearchParams(search);

export const initialState = {
  config,
  focusedMarker: null,
  hasLoaded: false,
  lastUpdated,
  markers: transfers,
  fileTypesByCity,
  start: false,
  totalUploadedFiles,
  transferPaths,
  transferTypesByDate,
  signupTypesPerDate,
  queryParams: params
};

export function reducer(state, action) {
  const { payload, type } = action;
  switch (type) {
    case 'LOADED':
      return {
        ...state,
        hasLoaded: true,
      };
    case 'START':
      return {
        ...state,
        start: true,
      };
    case 'FOCUS':
      return {
        ...state,
        focusedMarker: payload,
      };
      case 'DISABLE-PATHS':
        return {
          ...state,
          transferPaths: null,
        };
        case 'ENABLE-PATHS':
          return {
            ...state,
            transferPaths: transferPaths,
          };
    default:
      return state;
  }
}

const StateContext = createContext(null);

export function StateProvider({ children, initialState, reducer }) {
  return (
    <StateContext.Provider value={useReducer(reducer, initialState)}>
      {children}
    </StateContext.Provider>
  );
}

export function useStateValue() {
  return useContext(StateContext);
}
