import React, { useEffect } from 'react';

import Details from './details';
import Globe from './globe';
import Intro from './intro';
import Overlay from './overlay';
import { useStateValue } from '../state';
import Charts from './charts'
import AccumulativeTransfersChart from './accumulativeTransfersChart';
import AccumulativeSignupsChart from './accumulativeSignupsChart';

export default function App() {
  const [
    { queryParams, hasLoaded, transferTypesByDate, signupTypesPerDate, totalUploadedFiles },
    dispatch,
  ] = useStateValue();

  const showIntro = queryParams.get('showIntro') === 'true';
  const showCharts = queryParams.get('view') === 'charts';

  useEffect(() => {
    if(hasLoaded && !showIntro) {
      dispatch({ type: 'START' })
    }
  },[dispatch, hasLoaded, showIntro])

  function renderChartByType() {
    let chartToShow = queryParams.get('type');
    const chartHeight = 550;
    const chartWidth = 1100;
    switch (chartToShow) {
      case 'signups':
        return <Charts
        data = {signupTypesPerDate}
        headerText={signupTypesPerDate.map(({totalSignups}) => totalSignups).reduce((sum, x) => sum + x)}
        subHeaderText={'Accumulated Signups'}
        chartRenderer={() => <AccumulativeSignupsChart showTooltip={true} data={signupTypesPerDate} width={chartWidth} height={chartHeight}/>}
        backLink={'TRANSFERS_CHART'}
        />;
        case 'transfers':
        return <Charts
        data = {signupTypesPerDate}
        headerText={totalUploadedFiles}
        subHeaderText={'Accumulated Transfers'}
        chartRenderer={() => <AccumulativeTransfersChart showTooltip={true} data={transferTypesByDate} width={chartWidth} height={chartHeight}/>}
        nextLink={'SIGNUPS_CHART'}
        backLink={'HOME'}
        />;
        default:
        break;
    }

  }

  return (
      showCharts ? renderChartByType() : 
      <>
      <Globe />
     {showIntro && <Intro />} 
      <Overlay />
      <Details />
    </>
    

  );
}
